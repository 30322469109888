@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'DIN Pro';
    src: url('./fonts/dinpro/DINPro-Light.eot');
    src: local('DIN Pro Light'), local('DINPro-Light'),
        url('./fonts/dinpro/DINPro-Light.eot?#iefix') format('embedded-opentype'),
        url('./fonts/dinpro/DINPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('./fonts/dinpro/DINPro.eot');
    src: local('DIN Pro'), local('DINPro'),
        url('./fonts/dinpro/DINPro.eot?#iefix') format('embedded-opentype'),
        url('./fonts/dinpro/DINPro.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('./fonts/dinpro/DINPro-BoldItalic.eot');
    src: local('DIN Pro Bold Italic'), local('DINPro-BoldItalic'),
        url('./fonts/dinpro/DINPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/dinpro/DINPro-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('./fonts/dinpro/DINPro-Bold.eot');
    src: local('DIN Pro Bold'), local('DINPro-Bold'),
        url('./fonts/dinpro/DINPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/dinpro/DINPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('./fonts/dinpro/DINPro-MediumItalic.eot');
    src: local('DIN Pro Medium Italic'), local('DINPro-MediumItalic'),
        url('./fonts/dinpro/DINPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/dinpro/DINPro-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('./fonts/dinpro/DINPro-Black.eot');
    src: local('DIN Pro Black'), local('DINPro-Black'),
        url('./fonts/dinpro/DINPro-Black.eot?#iefix') format('embedded-opentype'),
        url('./fonts/dinpro/DINPro-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('./fonts/dinpro/DINPro-Medium.eot');
    src: local('DIN Pro Medium'), local('DINPro-Medium'),
        url('./fonts/dinpro/DINPro-Medium.eot?#iefix') format('embedded-opentype'),
        url('./fonts/dinpro/DINPro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('./fonts/dinpro/DINPro-Italic.eot');
    src: local('DIN Pro Italic'), local('DINPro-Italic'),
        url('./fonts/dinpro/DINPro-Italic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/dinpro/DINPro-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('./fonts/dinpro/DINPro-BlackItalic.eot');
    src: local('DIN Pro Black Italic'), local('DINPro-BlackItalic'),
        url('./fonts/dinpro/DINPro-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/dinpro/DINPro-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('./fonts/dinpro/DINPro-LightItalic.eot');
    src: local('DIN Pro Light Italic'), local('DINPro-LightItalic'),
        url('./fonts/dinpro/DINPro-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/dinpro/DINPro-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'DIN Pro';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: '#2D2D2D';
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

.sidebar {
  overflow: overlay;
  visibility: hidden;
}

.main-container {
  overflow-y: overlay;
}

.sidebar-content,
.sidebar:hover,
.sidebar:focus {
  visibility: visible;
}


::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #6736FF;
  border: 1px solid #D9D9D9;
  background-clip: content-box;
}

input, select, textarea {
  outline: none;
}

* {
  
  -webkit-print-color-adjust: exact;
  color-adjust: exact !important;    
}